@mixin for-desktop-up {
    @media (min-width: 1200px) {
        @content;
    }
}

.my-data {
    display: flex;
    flex-direction: column;

    @include for-desktop-up {
        display: grid;
        grid-template-columns: 2fr 1fr;
    }

    &__chart {
        &-title {
            display: flex;
            justify-content: space-between;
        }

        &-value {
            span {
                color: $primary;
            }
        }

        &-chart {
            display: flex;
            justify-content: center;
            align-items: center;
        }
    }

    &__data {
        text-align: center;

        &-item {
            padding: 10px 5px;
            border: 1px solid #dfe0eb;
            border-right: none;

            &:first-of-type {
                border-top-left-radius: 5px;
                border-bottom: none;
            }

            &:last-of-type {
                border-top: none;
                border-bottom: none;
            }
        }

        &-title {
            font-weight: lighter;
            font-size: 80%;
        }

        &-value {
            font-weight: bold;
            margin-top: 5px;
        }
    }
}

.dashboard-transactions .c-text-overflow {
    font-size: 13px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;

    @media (max-width: 1400px) {
        max-width: 190px;
    }

    @media (min-width: 1401px) {
        max-width: 350px;
    }
}

th.c-text-overflow,
td.c-text-overflow {
    @media (max-width: 1300px) {
        display: grid;
    }
}

.transactions-country-data {
    tr {
        border-bottom: 1px solid #dfe0eb;
    }
}
