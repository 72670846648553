.product-details {
    // &__ratings {
    // }

    // &__title {
    // }

    // &__owner {
    // }

    &__categories {
        label {
            background: #ffffff;
            border: 1px solid #cacaca;
            box-sizing: border-box;
            border-radius: 4px;
        }
    }

    // &__price {
    // }

    &__actions {
        .btn-primary {
            background-color: $primary;
            border-color: lighten($color: $primary, $amount: 10);

            &:hover,
            &:not(:disabled):not(.disabled).active,
            &:not(:disabled):not(.disabled):active {
                background-color: lighten($color: $primary, $amount: 10);
                outline: none;
                border: none;
            }
        }
    }

    &__description {
        @include for-desktop-up() {
            // margin-top: -15rem;
        }
    }
}

.nav-tabs .nav-item.show .nav-link,
.nav-tabs .nav-link.active {
    font-weight: bold;
}
