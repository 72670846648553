.asset-edit-image-col {
    min-height: 70vh;
}

.edit-asset-modal {
    font-size: 15px;
    font-weight: 450;
}

.asset-form-input {
    input {
        height: 30px;
        width: 100%;
        margin-bottom: 10px;
        box-sizing: border-box;
        box-shadow: none;
        border: 1px solid #0f2e42;
        outline: none !important;
        background: transparent;
    }
    textarea {
        height: 100px;
        width: 100%;
        margin-bottom: 10px;
        box-sizing: border-box;
        box-shadow: none;
        border: 1px solid #0f2e42;
        outline: none !important;
        background: transparent;
    }
    .custom-checkbox {
        height: 15px;
        width: 15px;
    }
    #price {
        width: 60%;
        margin: 10px 0px ;
    }
}
