.profile-page {
    &__avatar {
        img {
            width: 300px !important;
            height: 300px;
            object-fit: cover;
        }
    }
    /*    &__avatar {
        position: relative;

        &-overlay {
            position: absolute;
            top: 0;
            left: 0;
            right: auto;
            bottom: auto;
            height: 100%;
            width: 100%;
            background: rgba(0, 0, 0, 0.8);
            color: white;
            cursor: pointer;
            justify-content: center;
            align-items: center;
            display: none;
        }
        &:hover {
            .profile-page__avatar-overlay {
                display: flex;
            }
        }
    }
    */
}

.btn-primary {
    background-color: $primary;
    border-color: lighten($primary, 10);

    &:hover {
        background-color: lighten($color: $primary, $amount: 10);
    }
}

.btn-outline-primary {
    color: $primary;
    border: none;

    &:hover,
    &:focus,
    &:active {
        border: none;
        color: white;
        background-color: $primary;
    }
}
