// navbar
.sidebar {
    height: 100vh;
    background: $primary-background-color;
    padding: 0;
}

.bottom-border {
    border-bottom: 1px groove #eeeeee;
}
.sidebar-link {
    transition: all 0.4s;
    &:hover {
        background-color: #444444;
        border-radius: 5px;
    }
}

.top-navbar-welcome {
    background-color: $secondary-background-color;
    // box-shadow: 5px 7px 8px #999;
}

.current {
    background-color: #f0a84a;
    border-radius: 7px;
    box-shadow: 2px 5px 10px #444444;
    &:hover {
        background-color: #e0b47a;
        border-radius: 7px;
        box-shadow: 2px 5px 20px #444444;
        transform: translateY(-1px);
    }
}
.search-input {
    background: transparent;
    border: none;
    border-radius: 0;
    border-bottom: 2px solid #999;
    transition: all 0.4s;
    color: #ffffff;
    &:focus {
        background: transparent;
        box-shadow: none;
        border-bottom: 2px solid #dc3545;
        color: #ffffff;
    }
}

.search-btn {
    border-radius: 50%;
    padding: 10px 16px;
    transition: all 0.4s;
    &:hover {
        background-color: #eeeeee;
        transform: translateY(-1px);
    }
}
.icon-parent {
    position: relative;
}
.icon-bullet ::after {
    content: "";
    position: absolute;
    top: 7px;
    left: 15px;
    height: 12px;
    width: 12px;
    background-color: #f44336;
    border-radius: 50%;
}

@media (max-width: 768px) {
    .sidebar {
        position: static;
        height: auto;
    }
    .top-navbar {
        position: static;
    }
}

.card-dashboard {
    box-shadow: 1px 2px 5px #999999;
    transition: all 0.4s;
    &:hover {
        box-shadow: 2px 3px 15px #999999;
        transform: translateY(-1px);
    }
    min-height: 47vh;
}
.card-dashboard-2 {
    min-height: 40vh;
}

.profile-btn {
    padding: 5px;
    border-radius: 2px;
    outline: none !important;
    border: none;
}

.font-10 {
    font-size: 9px;
}
.font-9 {
    font-size: 9px;
}
.font-14 {
    font-size: 12px;
}
.card-footer-own {
    background: rgb(148, 134, 134);
}
.dot {
    fill: #ffab00;
    stroke: #fff;
}
