$light-grey: #f7f8fc;
$dark-grey: #363740;
$orange: #f2994a;
$menu-font-color: #DDE2FF;
$menu-font-color-selected: #9fa2b4;
$primary-font-family: "Mulish", sans-serif;

$primary: $orange;
$primary-background-color: $dark-grey;
$secondary-background-color: $light-grey;
