.storeWrappers {
  background: #8e0e00;
  background: -webkit-linear-gradient(to right, #1f1c18, #8e0e00);
  background: linear-gradient(to right, #1f1c18, #8e0e00);
  position: sticky;
}

#storeContainer {
  // background: #656058;
  // background: #0f0300;
  // The strain
  // background: #870000;
  // background: -webkit-linear-gradient(to right, #870000, #190a05);
  // background: linear-gradient(to right, #870000, #190a05);

  // Lawrencium

  // background: #0f0c29;
  // background: -webkit-linear-gradient(to right, #0f0c29, #302b63, #24243e);
  // background: linear-gradient(to right, #0f0c29, #302b63, #24243e);

  // selenium
  // background: #3c3b3f;
  // background: -webkit-linear-gradient(to right, #3c3b3f, #605c3c);
  // background: linear-gradient(to right, #3c3b3f, #605c3c);

  // Netflix
  // background: #8e0e00;
  // background: -webkit-linear-gradient(to right, #1f1c18, #8e0e00);
  // background: linear-gradient(to right, #1f1c18, #8e0e00);

  // COCO caramel
  //   background: #D1913C;  /* fallback for old browsers */
  // background: -webkit-linear-gradient(to right, #FFD194, #D1913C);  /* Chrome 10-25, Safari 5.1-6 */
  // background: linear-gradient(to right, #FFD194, #D1913C); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
}

.store-card {
  width: 220px;
  height: 55vh;
  margin-bottom: 20px;
  opacity: 0.9;
  &:hover {
    transform: scale(1.01);
    // background: linear-gradient(125deg, orange 60%, cyan);
  }
  margin-top: 20px;
  background: linear-gradient(125deg, rgb(99, 93, 81) 50%, rgb(148, 177, 177));
  // background-color: black;
}
.card-img {
  height: 62vh;
}
.store-card-header {
  width: 200px;
  height: 60vh;
}
.store-card-image {
  height: 60%;
  width: 100px;
}
.store-page {
  // background: linear-gradient(to left, rgba(75, 69, 48, 0.6), rgba(46, 45, 40, 0.9));
  background: black;
  min-height: 100vh;
  background-size: cover;
  background-position: center;
}
.store-card-title {
  font-size: 14px;
  font-family: Cambria, Cochin, Georgia, Times, "Times New Roman", serif;
}
.store-card-text {
  font-size: 12px;
}
.store-btn {
  width: 110%;
  height: 100%;
  font-size: 14px;
  margin: 0px;
}
.store-card-body {
  font-size: 14px;
}
.watermark {
  opacity: 0.3;
  font-size: 80px;
  font-weight: 700;
  color: "black";
  background: "#ccc";
  position: absolute;
  cursor: default;
  user-select: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  left: calc(30%);
  bottom: calc(50%);
}

.carousel-item-0 {
  height: 60vh;
}
.carousel-item-1 {
  @extend .carousel-item-0;
}
.carousel-item-2 {
  @extend .carousel-item-0;
}
.store-categories-2 {
  min-height: 29vh;
}
.store-categories-1 {
  min-height: 31vh;
}
.store-home {
  min-height: 100vh;
}
.store-bg {
  background: rgb(0, 0, 0);
}

.category-page {
  min-height: 120vh;
  background: rgb(0, 0, 0);
}

.category-card-image {
  height: 60%;
  width: 100%;
}
