.store-item {
  height: 300px;
  width: auto;
  // width: 20%;
  // margin: 20px;
  border-radius: 10px;
  position: relative;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.25);
  background: white;

  &:hover {
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.9);
  }

  @include for-big-desktop-up() {
    height: 400px;
    width: 50%;
  }

  @include for-phone-only() {
    height: 500px;
  }

  img {
    cursor: pointer;
    position: absolute;
    width: 100%;
    height: 300px;
    object-fit: cover;

    @include for-big-desktop-up() {
      height: 400px;
    }

    @include for-phone-only() {
      height: 500px;
    }
  }

  i {
    cursor: pointer;

    &:hover {
      border-radius: 100px;
      text-align: center;
      background-color: #efefefcc;
    }
  }

  &__header {
    z-index: 100;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    padding: 5px 5px 5px 10px;

    &-rating {
      display: flex;
      padding: 5px;
    }

    &-actions {
      display: flex;
      flex-direction: column;
    }
  }
  &__footer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: white;
    z-index: 100;
    height: 50px;
    padding: 0 10px;

    &-price {
      font-weight: 500;
    }

    &-actions {
      display: flex;
      justify-content: flex-end;
      width: 30%;
    }
  }

  @media only screen and (max-width: 1120px) {
    width: 100%;
  }
}

.store-promotional {
  width: 100%;
  height: 100px;
  border-radius: 10px;
  cursor: pointer;

  &:hover {
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.9);
  }

  p {
    color: white;
    width: 60%;
    height: 100%;
    padding: 10px;
    font-weight: bold;
    font-size: 1.3rem;
  }
  @media only screen and (max-width: 720px) {
    margin: 15px 0;
  }
}
