@import url("https://fonts.googleapis.com/css?family=Poppins:300,400,500,600,700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Mulish:ital,wght@0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");

@import "variables";
@import "mixins";
@import "components";
@import "pages";
@import "generic";
@import "animations";

/*
    DEMO STYLE
*/

body {
  font-family: "Inter", sans-serif;
  background: #fafafa;
}

p {
  font-family: "Inter", sans-serif;
  font-size: 1em;
  font-weight: 300;
  line-height: 1.7em;
  color: #999;
}

a,
a:hover,
a:focus {
  color: inherit;
  text-decoration: none;
  transition-timing-function: ease-out;

  /* A litttttle slower on the way in */
  transition: 0.15s;
}

// Superscript
sup {
  position: absolute;
  font-size: 8px;
  top: 8%;
}
.navbar {
  padding: 15px 10px;
  // background: #fff;
  border: none;
  border-radius: 0;
}

.navbar-btn {
  box-shadow: none;
  outline: none !important;
  border: none;
}

.line {
  width: 100%;
  height: 1px;
  border-bottom: 1px dashed #ddd;
  margin: 40px 0;
}
// Phone Input
.phoneInput {
  height: 35px !important;
  width: 100% !important;
  padding-left: 35px !important;
  margin-bottom: 15px !important;
  box-sizing: border-box !important;
  box-shadow: none !important;
  border: 1px solid #ef992f !important;
  border-radius: 30px !important;
  outline: none !important;
  background: transparent !important;
  margin-bottom: 15px !important;
  color: white !important;
}
.react-tel-input .form-control {
  margin-bottom: 15px !important;
}
.selected-flag:hover {
  background-color: transparent !important;
}
.selected-flag:focus {
  background-color: transparent !important;
}
.flag-dropdown.open {
  background-color: transparent;
}

// Terms of use
.terms {
  width: 100%;
  // padding-top: 30px;
  display: grid;
  place-items: center;
  .terms_con {
    width: 90%;
    height: 100%;
    & > p {
      color: black;

      font-size: 16px;
      line-height: 24px;
      margin: 0 0 16px;
      font-family: Arial, Helvetica, sans-serif;
      color: black;
      & > a {
        color: blue;
      }
    }
    .terms_con_1 {
    }
  }
}

/* ---------------------------------------------------
    SIDEBAR STYLE
----------------------------------------------------- */

.wrapper {
  display: flex;
  width: 100%;
  align-items: stretch;
}

#sidebar {
  min-width: 260px;
  max-width: 260px;
  background: #7386d5;
  color: #fff;
  transition: all 0.3s;
  min-height: 100vh;
  // overflow-y: auto;
  // position: fixed;
  // top: 0;
  // left: 0;
}
.sideBarContainer {
  width: 260px;
  position: fixed;
  max-height: 100vh;
  overflow-y: auto;
  &::-webkit-scrollbar {
    // scrollbar-width: thin;
    width: 0px;
    transition:  0.5s;
  }
}

.sideBarContainer.active {
  width:80px;
  transition:  0.5s;
}

#sidebar.active {
  margin-left: -260px;
}

#sidebar .sidebar-header {
  padding: 20px;
  background: #6d7fcc;
}

#sidebar ul.components {
  padding: 20px 0;
}

#sidebar ul p {
  color: #fff;
  padding: 10px;
}

#sidebar ul li a {
  padding: 10px;
  font-size: 1em;
  display: flex;
}

#sidebar ul li a:hover {
  color: #7386d5;
  background: rgba(255, 255, 255, 0.2);
}

#sidebar ul li.active > a,
a[aria-expanded="true"] {
  color: #fff;
  background: #6d7fcc;
}

a[data-toggle="collapse"] {
  position: relative;
}

.dropdown-toggle::after {
  display: block;
  position: absolute;
  top: 50%;
  right: 20px;
  transform: translateY(-50%);
}

ul ul a {
  font-size: 0.9em !important;
  padding-left: 30px !important;
  background: #6d7fcc;
}

ul.CTAs {
  padding: 20px;
}

ul.CTAs a {
  text-align: center;
  font-size: 0.9em !important;
  display: block;
  border-radius: 5px;
  margin-bottom: 5px;
}

a.download {
  background: #fff;
  color: #7386d5;
}

a.article,
a.article:hover {
  background: #6d7fcc !important;
  color: #fff !important;
}

footer {
  a:hover {
    color: #f2994a;
    text-decoration: none;
  }
}
ul.social-buttons li a {
  font-size: 20px;
  line-height: 40px;
  display: block;
  width: 40px;
  height: 40px;
  -webkit-transition: all 0.3s;
  -moz-transition: all 0.3s;
  transition: all 0.3s;
  color: #fff;
  border-radius: 100%;
  outline: 0;
  background-color: #343a40;
}
ul.social-buttons li a:active,
ul.social-buttons li a:focus,
ul.social-buttons li a:hover {
  background-color: #f2994a;
}
// Acknoledgements
.ack {
  color: black !important;
  font-weight: 500;
  width: 100%;
  height: 100%;
  font-family: Arial, Helvetica, sans-serif;
  .ack_1 {
    display: grid;
    place-items: center;
    height: 100%;
    width: 100%;
    .ack_1_c {
      background-color: white;
      width: 70%;
      height: 70%;
      overflow-y: auto;
      border: 1px solid rgb(114, 114, 114);
      padding: 12px;
      .ack_1_title {
        font-weight: 700 !important;
        margin-bottom: 12px;
        text-align: center;
      }
      .ack_1_txt {
        padding-bottom: 12px;
      }

      .ack_canv {
        width: 200px;
        height: 100px;
        border: 1px solid black;
      }

      $checkbox-background: #fff;
      $checkbox-border: #000000;
      $checkbox-checked: $checkbox-border;
      $checkbox-background-checked: yellow;

      @mixin vh() {
        border: 0;
        clip: rect(0 0 0 0);
        height: 1px;
        margin: -1px;
        overflow: hidden;
        padding: 0;
        position: absolute;
        width: 1px;
      }

      label {
        cursor: pointer;
        font-size: 1rem;
        line-height: 1rem;
      }

      input[type="checkbox"],
      input[type="radio"] {
        @include vh();
        &:focus {
          + label {
            &:before {
            }
          }
        }
        + label {
          position: relative;
          //padding: 4px 6px 0 32px;
          padding: 0.5rem 0 0 2rem;
          margin-bottom: 1rem;
          user-select: none;
          color: $checkbox-border;
          &:before {
            content: "";
            position: absolute;
            top: 0;
            left: 0;
            width: 1.75rem;
            height: 1.75rem;
            border: 2px solid $checkbox-border;
            border-radius: 0.25rem;
            background: $checkbox-background;
            text-align: center;
            transition: background 200ms ease-out;
          }
          &:after {
            content: "";
            //background-color: $checkbox-background;
            position: absolute;
            // top: 0.45rem;
            // left:  0.5rem;
            // width: 1rem;
            // height:  1rem;
            transform: scale(0);
            transition: transform 200ms ease-out;
          }
        }
      }

      input[type="checkbox"] {
        + label {
          &:after {
            background-color: transparent;
            content: "";
            display: block;
            position: absolute;
            left: 0.65rem;
            top: 0.25rem;
            // margin-left: 0;
            // margin-top: 0;
            width: 0.5rem;
            height: 1rem;
            opacity: 0.2;
            border-bottom: 3px solid $checkbox-checked;
            border-right: 3px solid $checkbox-checked;
            transform: rotate(45deg);
            transition: border-color 0.3s ease;
          }
        }
        &:checked {
          + label {
            &:before {
              content: "";
              //background: $checkbox-background-checked; change checked background color
            }

            &:after {
              content: "";
              opacity: 1;
              //border-bottom: 3px solid $checkbox-checked; change checked checkedbox color
              //border-right: 3px solid $checkbox-checked; change checked checkedbox color
            }
          }
        }
      }

      input[type="radio"] {
        + label {
          &:before,
          &:after {
            border-radius: 50%;
          }
          &:after {
            left: 0.35rem;
            top: 0.35rem;
            width: 1rem;
            height: 1rem;
          }
        }
        &:checked {
          + label {
            &:before {
              //background: $checkbox-background-checked;
              animation: borderscale 300ms ease-in;
            }
            &:after {
              background: $checkbox-checked;
              transform: scale(1);
            }
          }
        }
      }
    }
  }
}

/*
metamask
*/
.metamaskConnect {
  background-color: white;
  width: 42px;
  height: 42px;
  border-radius: 50%;
  .metamaskButton {
    width: 100%;
    height: 100%;
  }
}

.metamaskBtnPayment {
  &:hover {
    background-color: #f5b47a;
  }
}

//
.containerqw {
  display: flex;
  flex-direction: row;
}

/**
* VrificationPage 
*/
#wrapper {
  font-size: 16px;
  text-align: center;
  box-sizing: border-box;
  color: white;
  height: 100%;
  display: grid;
  place-items: center;
  #dialog {
    // border: solid 1px #ccc;
    margin: 10px auto;
    padding: 40px 60px;
    display: grid;
    place-items: center;
    // box-shadow: 0 0 4px #ccc;
    overflow: hidden;
    position: relative;
    // max-width: 450px;
    width: 100%;
    height: 100%;
    // background-color: #faf8f8;

    #OTPform {
      // max-width: 240px;
      margin: 25px auto 0;
      .otpInputReset {
        height: 40px !important;
        color: white !important;
        border-radius: 2px !important;
        z-index: 2 !important;
        background-color: transparent !important;
        margin-right: 5px !important;
        border: 1px solid $orange !important;
        width: 2rem !important;
        font-size: 28px;
        // line-height: 32px;
        padding-left: 5px;
        padding-right: 5px;
        padding: 5px;
        -webkit-box-sizing: border-box; /* Safari/Chrome, other WebKit */
        -moz-box-sizing: border-box; /* Firefox, other Gecko */
        box-sizing: border-box; /* Opera/IE 8+ */
      }
      input {
        // margin: 0 5px;
        // text-align: center;
        // line-height: 42px;
        // font-size: 24px;
        // border: solid 1px #ccc;
        // box-shadow: 0 0 5px #ccc inset;
        // outline: none;
        // width: 38px;
        // transition: all 0.2s ease-in-out;
        // border-radius: 3px;

        // &:focus {
        //   border-color: #f2994a;
        //   box-shadow: 0 0 5px #f2994a inset;
        // }

        &::selection {
          background: transparent;
        }
      }

      button {
        margin: 30px 0 50px;
        width: 50%;
        padding: 6px;
        background-color: #f2994a;
        border: none;
        text-transform: uppercase;
      }
    }

    button {
      &.close {
        border: solid 2px;
        border-radius: 30px;
        line-height: 19px;
        font-size: 120%;
        width: 22px;
        position: absolute;
        right: 5px;
        top: 5px;
      }
    }

    div {
      position: relative;
      z-index: 1;
    }

    img {
      position: absolute;
      bottom: -70px;
      right: -63px;
    }
  }
}

// OTP

.otpContainer {
  width: 100%;
  text-align: center;
  .otpContainer_txt {
    color: white !important;
    font-size: 14px;
    padding-bottom: 8px;
  }
  .otpContainer_otp {
    width: 100%;
    display: grid;
    place-items: center;
    .otpInputReset {
      height: 40px !important;
      color: white !important;
      border-radius: 2px !important;
      z-index: 2 !important;
      background-color: transparent !important;
      margin-right: 5px !important;
      border: 1px solid $orange !important;
      width: 2rem !important;
      font-size: 28px;
      // line-height: 32px;
      padding-left: 5px;
      padding-right: 5px;
      padding: 5px;
      -webkit-box-sizing: border-box; /* Safari/Chrome, other WebKit */
      -moz-box-sizing: border-box; /* Firefox, other Gecko */
      box-sizing: border-box; /* Opera/IE 8+ */
    }
  }
}

/*
    NOTIFICATION
*/
.notificationNumber {
  font-size: 14px;
  font-weight: 600;
  position: relative;
  top: -5px;
  left: -8;
  width: 0px;
  color: rgb(255, 0, 0);
}
.Dropdown {
  z-index: 3;
  position: absolute;
  top: 75px;
  min-width: 300px;
  transform: translate(-45%);
  border: 1px solid lighten($color: #242526, $amount: 50%);
  border-radius: 5px;
  padding: 5px;

  background-color: #f7f8fc;
  $heightoftotal: 95vh;
  $navbarHeight: 55px;
  height: auto !important;
  max-height: calc(#{$heightoftotal} - #{$navbarHeight}) !important;
  display: -webkit-flex;
  display: flex;
  width: 300px;
  overflow-y: auto;

  &::-webkit-scrollbar {
    width: 5px;

    background-color: rgba(255, 255, 255, 0);
  }

  &::-webkit-scrollbar-thumb {
    background-color: black;
    width: 5px;
    background-color: rgba(255, 255, 255, 0);
  }

  &:hover {
    &::-webkit-scrollbar {
      width: 5px;
      background-color: rgb(105, 105, 105);
    }

    &::-webkit-scrollbar-thumb {
      background-color: black;
      width: 5px;
    }
  }

  .NotificationDropDown {
    display: flex;
    flex-direction: column;
    width: 95%;
    margin: auto;

    .NotificationDropDown__topbar {
      height: auto;
      font-size: 18px;
      font-weight: bold;
      margin-top: 5px;
      margin-bottom: 5px;
    }

    .NotificationDropDown__eachNotification {
      width: 100%;
      display: grid;
      grid-template-columns: minmax(0px, 0px) 1fr;
      grid-template-areas: "eachNotiImageContainer notificationText";
      border-radius: 5px;
      margin-bottom: 5px;

      &:hover {
        background-color: darken($color: #f7f8fc, $amount: 1%);
        cursor: pointer;
      }

      .NotificationDropDown__eachNotification__imageContainer {
        grid-area: eachNotiImageContainer;
        display: grid;
        place-items: center;

        .NotificationDropDown__eachNotification__imageContainer__image {
          //   width: 60px;
          //   height: 60px;
          border-radius: 50%;
        }
      }

      .NotificationDropDown__eachNotification__notificationText {
        grid-area: notificationText;
        margin: 0;

        & > p {
          font-size: 14px;
          margin-bottom: 5px;
          color: black;
        }

        & > span {
          font-size: 12px;
        }
      }
    }
  }

  .Dropdown__buttonHolder {
    margin: auto;
    width: 95%;
    display: flex;
    flex-direction: column;

    .Dropdown__buttonHolder__profilebutton {
      width: 100%;
      color: white;
      background-color: #242526;
      height: 70px;
      margin-top: 5px;
      margin-bottom: 5px;
      padding-left: 0;

      .Dropdown__buttonHolder__profilebutton__text {
        padding-right: auto;
        margin-right: auto;
        font-size: 14px;
        text-decoration: none;
        text-transform: none;
      }

      .Dropdown__buttonHolder__profilebutton__imageContainer__image {
        height: 65px;
        width: 65px;
        border-radius: 50%;
        margin-right: auto;
      }

      &:hover {
        background-color: lighten($color: #242526, $amount: 50%);
      }
    }

    .Dropdown__buttonHolder__button {
      width: 100%;
      color: white;
      background-color: #242526;
      height: 50px;
      margin-bottom: 5px;
      font-size: 14px;
      text-transform: none;

      &:hover {
        background-color: lighten($color: #242526, $amount: 50%);
      }
    }
  }
}

/* ---------------------------------------------------
    CONTENT STYLE
----------------------------------------------------- */

#content {
  width: 100%;
  padding: 20px;
  // min-height: 100vh;
  transition: all 0.3s;
}

/* ---------------------------------------------------
    MEDIAQUERIES
----------------------------------------------------- */

@media (max-width: 768px) {
  #sidebar {
    margin-left: -260px;
  }
  #sidebar.active {
    margin-left: 0;
  }
  #sidebarCollapse span {
    display: none;
  }
}

.card-view-details {
  font-size: 75%;
  color: $primary;

  &:hover {
    color: darken($color: $primary, $amount: 10);
  }
}

.hide-names {
  max-width: 80px !important;
  min-width: 80px !important;

  span {
    display: none;
  }

  .col-3 {
    flex: 0 0 100%;
    max-width: 100% !important;
  }
}
.dropdown {
  position: relative;
  display: inline-block;
}

.dropdown-content {
  display: none;
  position: absolute;
  top: 50px;
  border-radius: 6px;
  background-color: #f9f9f9;
  min-width: 160px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  padding: 12px 0px;
  z-index: 1;
  span {
    color: #f2994a;
    font-size: 0.9rem;
    &:hover {
      text-decoration: underline;
    }
  }
}

.dropdown:hover .dropdown-content {
  display: block;
}
.dummyCard {
  width: 22%;
  @media (max-width: 1168px) {
    width: 43%;
  }
  @media (max-width: 768px) {
    width: 100%;
  }
}
@import "dashboard.page";
