.product-container {
    margin-top: 100px;
}

.new-product {
    background: green;
    width: fit-content;
    color: white;
    font-size: 12px;
    font-weight: bold;
    padding: 10px;
}

.description-col {
    color: #555555;
}
.star-rating-product {
    height: 15px;
    width: 80px;
}
.product-price {
    color: #FE980F;
    font-size: 16px;
    font-weight: bold;
    padding-top: 20px
}
.product-btn {
    margin: 10px;
    padding: 10px;
}
.product-short-header {
    color: #151583;
    font-size: 20px;
}