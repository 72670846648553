.form-input{
    .fa {
        position: absolute;
        top: 10px;
        left: 10px;
        color: grey;
    }
}

.text-primary {
    color: $primary !important;
    font-weight: 400;
}

.main-title {
    font-family: $primary-font-family;
    font-weight: 500;
    font-size: 1rem;
    color: $menu-font-color;
    letter-spacing: 0.4px;
}

.sidebar-menu-item {
    border-left: solid 0px $primary-background-color;

    span {
        color: #A4A6B3;
    }
}

.sidebar-menu-item-selected {
    border-left: solid 3px $menu-font-color;
    background-color: lighten($color: $primary-background-color, $amount: 5);
    span {
        color: $menu-font-color;
    }
}
.icon-width {
    width: 20px !important;
}
