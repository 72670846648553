body {
    margin: 0;
    font-family: $primary-font-family;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    width: 100%;
    height: 100%;
    min-height: 100vh;
    overflow-x: hidden !important;
}

a {
    text-decoration: none;
    &:hover {
        text-decoration: none;
    }
}

.relative {
    position: relative;
}

main {
    position: relative;
}

section {
    background-color: $secondary-background-color;
}

* {
    outline: none;
}

.cursor-pointer {
    cursor: pointer;
}
