.asset-modal {
    width: 30vw;
}

.asset-modal-body {
    position: relative;
    overflow-y: auto;
    max-height: 400px;
    padding: 15px;
    label {
        font-weight: 550;
    }
    input,
    select {
        height: 30px;
        width: 90%;
        margin-left: 20px;
        margin-bottom: 20px;
        box-sizing: border-box;
        padding-left: 10px;
        border: 1px solid rgb(3, 3, 3);
        outline: none !important;
        border-radius: 2.5px;
    }
    textarea {
        width: 90%;
        margin-left: 20px;
        margin-bottom: 20px;
        box-sizing: border-box;
        padding-left: 10px;
        border: 1px solid rgb(3, 3, 3);
        outline: none !important;
        border-radius: 2.5px;
    }
    #term2 {
        height: 15px;
        padding: 10px 0;
        display: inline-block;
        vertical-align: middle;
    }
    #term1 {
        width: 100%;
        padding: 0px;
        margin: 10px 0;
    }
}

#productType {
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    border-radius: 5px;
}
.dot {
    height: 15px;
    width: 15px;
    background-color: rgb(235, 14, 14);
    border-radius: 50%;
    display: inline-block;
    &.active {
        background-color: rgb(14, 235, 114);
    }
}

.asset-table {
    font-size: 10px;
}
