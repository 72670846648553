.pre-login-home {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-image: linear-gradient(
      rgba(000, 000, 000, 0.5),
      rgba(000, 000, 000, 0.5)
    ),
    url("../../Assets/registerLogin/login-background.jpg");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}
.pre-login-card {
  width: 90%;
  min-height: 80vh;
  border-radius: 10px !important;
  box-shadow: 0 0.5rem 1rem 0 rgba(0, 0, 0, 0.1);
  @media only screen and (max-width: 720px) {
    width: 100%;
  }
}

.img-left {
  width: 100%;
  background-image: linear-gradient(
    rgba(000, 000, 000, 0.5),
    rgba(000, 000, 000, 0.5)
  );
  backdrop-filter: blur(2px);
  -webkit-backdrop-filter: blur(2px);
}
.pre-login-card-body {
  padding: 1rem;
}

.card-back {
  background: rgba(0, 0, 0, 0.4);
  backdrop-filter: blur(2px);
  -webkit-backdrop-filter: blur(2px);
}
.title {
  margin-bottom: 1rem;
  color: #fff;
}
.pre-login-card-body {
  input,
  select {
    color: #fff;
    font-weight: 500;
  }
}
.form-input {
  position: relative;

  input,
  select {
    height: 35px;
    width: 100%;
    padding-left: 35px;
    margin-bottom: 15px;
    box-sizing: border-box;
    box-shadow: none;
    // color: #fff;
    border: 1px solid #ef992f;
    border-radius: 30px;
    outline: none !important;
    background: transparent;
  }

  // span {
  //     position: absolute;
  //     top: 5px;
  //     padding-left: 10px;
  //     color: #ef992f;
  // }
  // ::placeholder {
  //     color: #fff;
  //     padding-left: 0px;
  // }
}

.custom-checkbox .custom-control-input:checked ~ .custom-control-label::before {
  background-color: #ef992f !important;
  border: 0px;
}

.pre-login-form-box {
  button[type="submit"] {
    margin-top: 10px;
    width: 100%;
    border: 1px solid #ef891f;
    cursor: pointer;
    border-radius: 30px;
    color: #ffffff;
    background-color: #ef891f;
    font-size: 80%;
    font-weight: bold;
    letter-spacing: 0.1rem;
    transition: 0.5s;
    padding: 12px;
    &:hover {
      background: transparent;
      color: #ef891f;
    }
  }
}
.forget-link,
.register-link {
  color: #ef992f;
  font-weight: bold;
  &:hover {
    color: #f1a457;
    text-decoration: none;
  }
}
.btn-social {
  color: white !important;
  border: 0;
  font-weight: bold;
  border-radius: 5px;
}
.btn-facebook {
  background: #4866a8;
  &:hover {
    background: lighten(#4866a8, 10%);
  }
}
.btn-google {
  background: #da3f34;
  &:hover {
    background: lighten(#da3f34, 10%);
  }
}

.version-text {
  position: absolute;
}
.font-12 {
  font-size: 12px;
}
.tag-line {
  // -webkit-text-fill-color: transparent;
  background: rgba(0, 0, 0, 0)
    linear-gradient(
      to right,
      #fff 0px,
      #fff 35%,
      rgb(242, 162, 79) 66%,
      rgb(242, 162, 79) 100%
    )
    repeat scroll 0% 0% / 200% text !important;

  animation: 10s linear 0s infinite normal none running shine;
  font-family: "Poppins", sans-serif;
  font-size: 2vw !important;
  font-weight: 800;
  @keyframes shine {
    0% {
      background-position: 0 43%;
    }
    50% {
      background-position: 100% 58%;
    }
    100% {
      background-position: 0 43%;
    }
  }
}
